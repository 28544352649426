'use client';
import { ideaClipsImage } from '@/config/img/ideaclips';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { TbMenu2 } from 'react-icons/tb';
import { Popover, PopoverTrigger, PopoverContent, Image } from '@nextui-org/react';

interface IProps {
  page: 'home' | 'pdf';
}

export default function Header(props: IProps) {


  return (
    <>
      {/* pc */}
      <div className='bg-[#E2F4FE] w-full h-20 px-[150px] flex items-center justify-between max-md:hidden'>
        <Link href={'/'}>
          <Image radius='none' src={ideaClipsImage.HeaderLogo} alt='logo' width={140} height={42} />
        </Link>
        <Link href="/pdf/1" className='z-20'>
          <span className={clsx('text-22-30 font-semibold z-10 cursor-pointer', props.page === 'pdf' ? 'text-[#1CA1EC]' : '')}>PDF</span>
        </Link>
      </div>
      {/* mobile */}
      <div className='hidden max-md:flex h-12 bg-[#E2F4FE] w-full  items-center justify-between'>
        <Link href={'/'}>
          <Image radius='none' src={ideaClipsImage.HeaderLogo} alt='logo' width={110} height={33} />
        </Link>
        <Popover
          classNames={{
            content: 'bg-transparent relative w-screen rounded-none',
          }}
          placement='bottom-start'
        >
          <PopoverTrigger>
            <div className='flex items-center'>
              <TbMenu2 className='w-6 h-6 text-black' />
            </div>
          </PopoverTrigger>
          <PopoverContent >
            <div className="lang-list w-screen py-5 absolute top-0 -left-3 text-center bg-white text-18-27">
              <div
               className={ clsx(props.page === 'pdf' && 'text-[#E2F4FE]', 'cursor-pointer') }
              >
                <Link href='/pdf/1'>
                  PDF
                </Link>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
