export const ideaClipsImage = {
  HeaderLogo: '/header-logo.png',
  HeaderImage: '/header-image.png',
  BottomImage: '/bottom-image.png',
  PdfHeader: '/pdf-header.png',
  PDF: '/pdf.png',
  MobileHeaderImage: '/mobile-head-image.png',
  MobileBottom: '/mobile-bottom.png',
  MobilePdfHeader: '/mobile-pdf-head.png'
};
